import React, { useMemo } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HistoryProvider } from '@tanium/react-history-context';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { ToastNotificationsWrapper } from '@tanium/react-toast-notifications-wrapper';
import { bluestar, darkmode } from '@tanium/theme-data';
import { Box } from '@tanium/react-box';
import { useConfig } from '../../hooks/useConfig';
import { AppContainer } from './AppContainer';
import { ThemeWrapper } from './ThemeWrapper';

const savedTheme = window.localStorage.getItem('preference.theme') === 'dark' ? darkmode : bluestar;

export const App: React.VFC = () => {
  const history = useMemo(() => createBrowserHistory(), []);
  const { config } = useConfig();

  return (
    <ThemeWrapper theme={savedTheme}>
      <Router history={history}>
        <HistoryProvider history={history}>
          <ToastNotificationsWrapper>
            {config ? (
              <AppContainer authConfig={config.getAuthConfig()} />
            ) : (
              <Box height="100vh">
                <TaniumSpinner />
              </Box>
            )}
          </ToastNotificationsWrapper>
        </HistoryProvider>
      </Router>
    </ThemeWrapper>
  );
};
