import React, { Suspense, useCallback } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { AuthState, CognitoAuth, useCognitoAuth } from '../../auth/useCognitoAuth';
import { ApplicationAuthConfig } from '../../config';
import { LoginPage } from '../../pages/Login/LoginPage';
import { LOGOUT_ROUTE } from '../../Routes';
import { SessionContext } from '../../session/SessionContext';
import { AppHeaderStyle } from '../AppHeader/AppHeaderStyle';
import { AppHeader } from '../AppHeader/AppHeader';
import { AppContent } from './AppContent';

const headerHeight = AppHeaderStyle.borderHeight + AppHeaderStyle.containerHeight;

const Body = styled(Box)(
  css({
    marginTop: `${headerHeight}px`,
    height: `calc(100vh - ${headerHeight}px)`,
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
);

const UnauthenticatedArea: React.VFC<{ cognitoAuth: CognitoAuth }> = ({ cognitoAuth }) => {
  const location = useLocation();
  const { signIn } = cognitoAuth;
  const redirectToIdp = useCallback(async () => {
    localStorage.setItem('saved-location', location.pathname);
    await signIn();
    return <TaniumSpinner />;
  }, [location, signIn]);
  const renderLoginPage = useCallback(() => <LoginPage cognitoAuth={cognitoAuth} />, [cognitoAuth]);

  return (
    <Switch>
      <Route path={LOGOUT_ROUTE.url} exact render={renderLoginPage} />
      <Route render={redirectToIdp} />
    </Switch>
  );
};

interface Props {
  authConfig: ApplicationAuthConfig;
}

export const AppContainer: React.VFC<Props> = ({ authConfig }) => {
  const cognitoAuth = useCognitoAuth(authConfig);
  const { session, authState } = cognitoAuth;

  if (authState === AuthState.LOADING) {
    return (
      <Box height="100vh">
        <TaniumSpinner />
      </Box>
    );
  }

  return (
    <Suspense
      fallback={
        <Box height="100vh">
          <TaniumSpinner />
        </Box>
      }
    >
      {authState === AuthState.SIGNED_IN && session ? (
        <SessionContext.Provider value={session}>
          <AppHeader />
          <Body>
            <AppContent />
          </Body>
        </SessionContext.Provider>
      ) : (
        <UnauthenticatedArea cognitoAuth={cognitoAuth} />
      )}
    </Suspense>
  );
};
