import React from 'react';
import { Box } from '@tanium/react-box';
import { Stack } from '@tanium/react-stack';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { Login } from '../../components/Login/Login';
import { AuthState, CognitoAuth } from '../../auth/useCognitoAuth';

interface LoginPageProps {
  cognitoAuth: CognitoAuth;
}

export const LoginPage: React.VFC<LoginPageProps> = ({ cognitoAuth }) => {
  const { authState, signIn } = cognitoAuth;
  if (authState === AuthState.SIGN_IN_IDP) {
    return <Login onLogin={signIn} />;
  }
  return (
    <Box as={Stack} justifyContent="center" marginTop="150px">
      <TaniumSpinner />
    </Box>
  );
};
