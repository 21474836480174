import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeColors } from '@tanium/theme-data';

export const SmallText = styled('span')(
  css({
    fontSize: 12,
  }),
);

export const SmallBoldText = styled(SmallText)(
  css({
    fontWeight: 'bold',
  }),
);

export const MediumText = styled('span')(
  css({
    fontSize: 14,
  }),
);

export const MediumSubtleText = styled(MediumText)(
  css({
    color: ThemeColors.TextSecondary,
    fontWeight: 'normal',
  }),
);

export const MediumHighlightedText = styled(MediumText)(
  css({
    color: ThemeColors.Action,
    fontWeight: 'normal',
  }),
);

export const MediumBoldText = styled(MediumText)(
  css({
    fontWeight: 'bold',
  }),
);

export const LargeText = styled('span')(
  css({
    fontSize: 16,
  }),
);

export const LargeBoldText = styled('span')(
  css({
    fontSize: 16,
    fontWeight: 'bold',
  }),
);
