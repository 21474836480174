import React, { useState } from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as TaniumThemeProvider } from '@tanium/react-theme-context';
import { Theme } from '@tanium/theme-data';
import { ThemeWrapperContext } from '../../contexts/ThemeWrapperContext';

interface Props {
  theme: Theme;
  children?: React.ReactNode;
}

export const ThemeWrapper = React.memo(({ theme, children }: Props) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(theme);

  return (
    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
    <ThemeWrapperContext.Provider value={{ setCurrentTheme }}>
      <EmotionThemeProvider theme={currentTheme}>
        <TaniumThemeProvider theme={currentTheme}>{children}</TaniumThemeProvider>
      </EmotionThemeProvider>
    </ThemeWrapperContext.Provider>
  );
});
