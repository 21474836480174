import { useMemo } from 'react';
import { NavSection } from '@tanium/react-workbench';
import { useRbac } from './useRbac';
import {
  ADVISORIES_ROUTE,
  ADVISORY_DRAFTS_ROUTE,
  ADVISORY_QUERY_ROUTE,
  OSS_PENDING_APPROVALS_ROUTE,
  OSS_REQUESTS_ROUTE,
  OSS_SOFTWARES_ROUTE,
  TOKENS_ROUTE,
} from '../Routes';

export const useNavSections = (): NavSection[] | undefined => {
  const u = useRbac();
  return useMemo(() => {
    const advisorySection = {
      sectionId: 'advisories',
      name: 'Advisories',
      subSections: [
        ...(u.canListDrafts
          ? [
              {
                name: 'Drafts',
                route: ADVISORY_DRAFTS_ROUTE.key,
                url: ADVISORY_DRAFTS_ROUTE.url,
              },
            ]
          : []),
        ...(u.canListAdvisories
          ? [
              {
                name: 'Published',
                route: ADVISORIES_ROUTE.key,
                url: ADVISORIES_ROUTE.url,
              },
            ]
          : []),
        ...(u.canQueryAdvisories
          ? [
              {
                name: 'Applicability',
                route: ADVISORY_QUERY_ROUTE.key,
                url: ADVISORY_QUERY_ROUTE.url,
              },
            ]
          : []),
      ],
    };
    const ossSection = {
      sectionId: 'oss',
      name: 'Open Source',
      subSections: [
        ...(u.canListOssSoftware
          ? [
              {
                name: 'Software',
                route: OSS_SOFTWARES_ROUTE.key,
                url: OSS_SOFTWARES_ROUTE.url,
              },
            ]
          : []),
        ...(u.canEditOssRequests
          ? [
              {
                name: 'My Requests',
                route: OSS_REQUESTS_ROUTE.key,
                url: OSS_REQUESTS_ROUTE.url,
              },
            ]
          : []),
        ...(u.canListOssRequests
          ? [
              {
                name: 'Pending Approval',
                route: OSS_PENDING_APPROVALS_ROUTE.key,
                url: OSS_PENDING_APPROVALS_ROUTE.url,
              },
            ]
          : []),
      ],
    };
    const adminSection = {
      sectionId: 'admin',
      name: 'Administration',
      subSections: [
        ...(u.canListTokens
          ? [
              {
                name: 'API Tokens',
                route: TOKENS_ROUTE.key,
                url: TOKENS_ROUTE.url,
              },
            ]
          : []),
      ],
    };

    const navSections = [] as NavSection[];
    if (advisorySection.subSections.length > 0) {
      navSections.push(advisorySection);
    }
    if (ossSection.subSections.length > 0) {
      navSections.push(ossSection);
    }
    if (adminSection.subSections.length > 0) {
      navSections.push(adminSection);
    }
    return navSections;
  }, [u]);
};
