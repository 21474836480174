import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Icon } from '@tanium/react-graphics';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { Workbench } from '@tanium/react-workbench';
import ProductSecurityIcon from '../../static/ProductSecurity-ModuleIcon-32.svg';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import { useSession } from '../../session';
import { useNavSections } from '../../hooks/useNavSections';
import { NOT_FOUND_PAGE, usePages } from '../../hooks/usePages';
import { LOGOUT_ROUTE, OSS_SOFTWARES_ROUTE } from '../../Routes';
import { usePreferences } from '../../hooks/usePreferences';

export const AppContent: React.VFC = () => {
  const currentRoute = useCurrentRoute();
  const { endSession } = useSession();
  const navSections = useNavSections();
  const pages = usePages();
  const prefs = usePreferences();

  const redirectFromHome = useCallback(() => {
    const savedLocation = localStorage.getItem('saved-location');
    if (savedLocation) {
      localStorage.removeItem('saved-location');
      return savedLocation;
    }
    return prefs.homepage;
  }, [prefs]);
  const redirectToLogout = useCallback(() => {
    endSession();
    return <TaniumSpinner />;
  }, [endSession]);

  const page = pages.find((p) => p.route.key === currentRoute);
  const {
    route: { url: path },
    breadcrumbs,
    label,
    component,
  } = page || NOT_FOUND_PAGE;

  return (
    <Workbench
      productName="Tanium Product Security Portal"
      homeUrl="/home"
      icon={ProductSecurityIcon as Icon}
      breadcrumbs={breadcrumbs}
      currentPageLabel={label}
      defaultSections={navSections}
      renderedRoute={currentRoute}
    >
      <Switch>
        <Redirect from="/" exact to={prefs.homepage} />
        <Redirect from="/home" exact to={redirectFromHome()} />
        <Redirect from="/oss" exact to={OSS_SOFTWARES_ROUTE.url} />
        <Route path={LOGOUT_ROUTE.url} exact render={redirectToLogout} />
        <Route path={path} component={component} />
      </Switch>
    </Workbench>
  );
};
