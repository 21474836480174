export interface ApplicationAuthConfig {
  userPoolId: string;
  appClientId: string;
  region: string;
  domain: string;
  customProvider: string;
}

interface ConfigData {
  apiBaseUrl: string;
  authConfig: ApplicationAuthConfig;
}

const DEFAULT_API_URL = 'http://localhost:8080/';
const DEFAULT_AUTH_CONFIG = {
  userPoolId: '',
  appClientId: '',
  region: '',
  domain: '',
  customProvider: '',
};

export class Config {
  private static configInstance: Config | undefined = undefined;

  private static fatalError: Error | undefined = undefined;

  private config: ConfigData = {
    apiBaseUrl: DEFAULT_API_URL,
    authConfig: DEFAULT_AUTH_CONFIG,
  };

  private constructor(data: ConfigData) {
    this.config = data;
  }

  public static async getInstance(): Promise<Config> {
    if (Config.fatalError) {
      throw Config.fatalError;
    }
    if (!Config.configInstance) {
      try {
        Config.configInstance = await Config.loadFromUrl('/config.json');
        Config.fatalError = undefined;
      } catch (e) {
        Config.configInstance = undefined;
        Config.fatalError = e as Error;
        throw e;
      }
    }
    return Config.configInstance;
  }

  private static async loadFromUrl(url: string) {
    const res = await fetch(url);
    if (res.ok) {
      return new Config((await res.json()) as ConfigData);
    }
    throw Error(
      `error loading configuration data: ${res.status} ${res.statusText}\n${await res.text()}`,
    );
  }

  public getAuthConfig(): ApplicationAuthConfig {
    return this.config.authConfig;
  }

  public resolveApiUrl(urlOrPath: string): URL {
    // don't modify absolute URLs (which are most likely external)
    if (urlOrPath.startsWith('https://') || urlOrPath.startsWith('http://')) {
      return new URL(urlOrPath);
    }

    let baseUrl;
    if (this.config.apiBaseUrl) {
      if (
        this.config.apiBaseUrl.startsWith('https://') ||
        this.config.apiBaseUrl.startsWith('http://')
      ) {
        baseUrl = new URL(this.config.apiBaseUrl);
      } else {
        baseUrl = new URL(this.config.apiBaseUrl, window.location.origin);
      }
    } else {
      baseUrl = new URL('/ui/', window.location.origin);
    }

    return new URL(urlOrPath, baseUrl);
  }
}

export const getConfig = async (): Promise<Config> => Config.getInstance();
