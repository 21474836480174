import { useCallback, useEffect, useMemo, useState } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCallback } from '@aws-amplify/core';
import { CognitoAuthenticatedUser } from '.';
import { SessionState } from '../session';

import { ApplicationAuthConfig } from '../config';
import { useAsyncEffect } from '../hooks/useAsyncEffect';

export enum AuthState {
  LOADING = 'loading',
  SIGNED_IN = 'signed_in',
  SIGN_IN_IDP = 'sign_in_idp',
}

export interface CognitoAuth {
  session: SessionState | null;
  authState: AuthState;
  signIn: () => Promise<void>;
  resetAuthState: () => void;
  signOut: () => Promise<void>;
}

const getSessionState = (
  authenticatedUser: CognitoAuthenticatedUser | null,
  endSession: () => Promise<void>,
): SessionState | null => {
  const userSession = authenticatedUser?.getSignInUserSession();
  if (!userSession?.isValid()) {
    return null;
  }
  const username = authenticatedUser?.attributes?.email ?? '';
  const primaryOrg = (userSession?.getIdToken().payload?.primaryOrg as string) ?? '';
  const privileges = ((userSession?.getIdToken().payload?.privileges as string) ?? '').split(',');
  return {
    currentUser: {
      name: username,
      primaryOrg,
      privileges,
    },
    endSession,
  };
};

const useCognito = ({ userPoolId, appClientId, region, domain }: ApplicationAuthConfig): void => {
  useEffect(() => {
    Auth.configure({
      region,
      userPoolId,
      userPoolWebClientId: appClientId,
      oauth: {
        domain,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.origin}/home`,
        redirectSignOut: `${window.location.origin}/logout`,
        responseType: 'token',
      },
      storage: window.sessionStorage,
    });
  }, [userPoolId, region, appClientId, domain]);
};

export const useCognitoAuth = (authConfig: ApplicationAuthConfig): CognitoAuth => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [authState, setAuthState] = useState(AuthState.LOADING);

  useCognito(authConfig);

  const resetAuthState = useCallback(() => {
    setUser(null);
    setAuthState(AuthState.SIGN_IN_IDP);
  }, []);

  const updateAuthState = useCallback(
    async (currentUser: CognitoUser | null): Promise<void> => {
      if (!currentUser) {
        // initial auth state
        resetAuthState();
        return;
      }

      if (currentUser.getSignInUserSession()?.isValid()) {
        const signedInUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        setUser(signedInUser);
        setAuthState(AuthState.SIGNED_IN);
        return;
      }

      // current user session is invalid so return user to sign in page
      resetAuthState();
    },
    [resetAuthState, setUser, setAuthState],
  );

  const getUser = useCallback(async () => {
    try {
      const currentUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      await updateAuthState(currentUser);
    } catch (err) {
      // currentAuthenticatedUser throws when user is not authenticated
      resetAuthState();
    }
  }, [updateAuthState, resetAuthState]);
  useAsyncEffect(getUser, []);

  const signIn = useCallback(async () => {
    await Auth.federatedSignIn({ customProvider: authConfig.customProvider });
  }, [authConfig]);

  const signOut = useCallback(async () => {
    await Auth.signOut();
    resetAuthState();
  }, [resetAuthState]);

  const session = useMemo(() => getSessionState(user, signOut), [signOut, user]);

  useEffect(() => {
    const authListener: HubCallback = async ({ payload }) => {
      if (payload.event === 'tokenRefresh_failure') {
        await signOut();
      }
    };

    Hub.listen('auth', authListener);
    return () => {
      Hub.remove('auth', authListener);
    };
  }, [signOut]);

  return {
    session,
    authState,
    signIn,
    resetAuthState,
    signOut,
  };
};
