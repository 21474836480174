import React, { useCallback, useState } from 'react';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { Box } from '@tanium/react-box';
import { Stack } from '@tanium/react-stack';
import { ThemeColors, ThemeShadows, ThemeSpace } from '@tanium/theme-data';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@tanium/react-graphics';
import { SmallText } from '../../TextStyles';
import { useSession } from '../../session';

const MenuLink = styled(Link)(
  css({
    p: ThemeSpace[1],
    color: ThemeColors.Light0,
    textDecoration: 'none',
    fontSize: '13px',
    width: '100%',
    display: 'block',
    ':hover': {
      bg: ThemeColors.HeaderTier2,
      color: ThemeColors.HeaderLinkHover,
    },
  }),
);

const DownArrow = styled(ChevronRightIcon)(
  css({
    color: ThemeColors.Tint3,
    transform: 'rotate(90deg)',
  }),
);

const style = { cursor: 'pointer' };

export const UserInfo: React.VFC = () => {
  const { currentUser } = useSession();
  const [menuVisible, setMenuVisible] = useState(false);

  const onMouseEnter = useCallback(() => {
    setMenuVisible(true);
  }, [setMenuVisible]);

  const onMouseLeave = useCallback(() => {
    setMenuVisible(false);
  }, [setMenuVisible]);

  return (
    <Box
      position="relative"
      px={1}
      mx={1}
      height="100%"
      bg={menuVisible ? ThemeColors.Dark0 : ThemeColors.HeaderTier2}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
    >
      <Box as={Stack} color={ThemeColors.Light0} height="100%" alignItems="center">
        <Box mr={1}>
          <SmallText>{currentUser?.name}</SmallText>
        </Box>
        <DownArrow height={10} />
      </Box>
      <Box
        bg={ThemeColors.Dark0}
        py={2}
        width="100%"
        borderBottomRadius={1}
        boxShadow={ThemeShadows.Overlay2}
        display={menuVisible ? 'inherit' : 'none'}
        position="absolute"
        right={0}
        zIndex={2}
      >
        <Stack direction="column" width="100%">
          <MenuLink to="/logout">Sign out</MenuLink>
        </Stack>
      </Box>
    </Box>
  );
};
