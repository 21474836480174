import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { Icon } from '@tanium/react-graphics';
import { css } from '@tanium/style-system';
import { ThemeColors } from '@tanium/theme-data';
import { AppHeaderStyle } from './AppHeaderStyle';
import { UserInfo } from './UserInfo';
import TaniumLogo from '../../static/tanium_logo.svg';

const DEFAULT_LOGO_HEIGHT = 24;

const Logo: React.VFC = () => (
  <Link to="/">
    <img src={TaniumLogo as Icon} alt="Tanium logo" height={DEFAULT_LOGO_HEIGHT} />
  </Link>
);

const devModeBannerStyle = {
  color: 'orange',
  'font-weight': 'bold',
  'font-size': 'large',
  'text-align': 'center',
};

const DevModeBanner: React.VFC = () => {
  const [isDevMode, setDevMode] = useState(false);
  useEffect(() => {
    fetch('/static/.devmode')
      .then(async (res) => {
        if (res.ok) {
          const data = await res.text();
          if (data === '') {
            setDevMode(true);
            return;
          }
        }
        setDevMode(false);
      })
      .catch(() => {
        /* ignored */
      });
  }, []);
  return isDevMode ? <div style={devModeBannerStyle}>*** LOCAL DEV MODE ***</div> : <></>;
};

const Container = styled(Box)(
  css({
    color: ThemeColors.Active2,
    bg: ThemeColors.HeaderTier2,
    height: `${AppHeaderStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
  }),
);

export const AppHeader: React.VFC = () => (
  <Box position="fixed" top={0} width="100%" zIndex={1}>
    <Box bg={ThemeColors.BrandRed} height={`${AppHeaderStyle.borderHeight}px`} width="100%" />
    <Container>
      <Box mx={3}>
        <Logo />
      </Box>
      <Box mx={3} flexGrow={1}>
        <DevModeBanner />
      </Box>
      <UserInfo />
    </Container>
  </Box>
);
