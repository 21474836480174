import { WorkbenchRoute } from '@tanium/workbench-router';

export const ADVISORIES_ROUTE: WorkbenchRoute = {
  name: 'Security Advisories',
  key: 'advisories',
  url: '/advisories',
};

export const ADVISORY_ROUTE: WorkbenchRoute = {
  name: 'Advisory',
  key: 'advisory',
  url: '/advisories/:id',
};

export const ADVISORY_DRAFTS_ROUTE: WorkbenchRoute = {
  name: 'Draft Advisories',
  key: 'advisory-drafts',
  url: '/advisory-drafts',
};

export const ADVISORY_DRAFT_ROUTE: WorkbenchRoute = {
  name: 'Draft Advisories',
  key: 'advisory-draft',
  url: '/advisory-drafts/:id',
};

export const ADVISORY_DRAFT_FORM_ROUTE: WorkbenchRoute = {
  name: 'Draft Form',
  key: 'advisory-draft-form',
  url: '/advisory-draft-form',
};

export const ADVISORY_DRAFT_EDIT_ROUTE: WorkbenchRoute = {
  name: 'Edit Advisory',
  key: 'advisory-draft-edit',
  url: '/advisory-drafts/:id/edit',
};

export const ADVISORY_QUERY_ROUTE: WorkbenchRoute = {
  name: 'Advisory Query',
  key: 'advisory-query',
  url: '/advisory-query',
};

export const DEBUG_ROUTE: WorkbenchRoute = {
  name: 'Debug',
  key: 'debug',
  url: '/debug',
};

export const LOGOUT_ROUTE: WorkbenchRoute = {
  name: 'Sign out',
  key: 'logout',
  url: '/logout',
};

export const OSS_REQUEST_FORM_ROUTE: WorkbenchRoute = {
  name: 'Open Source Request Form',
  key: 'oss-request-form',
  url: '/oss/request-form',
};

export const OSS_REQUESTS_ROUTE: WorkbenchRoute = {
  name: 'Open Source Requests',
  key: 'oss-requests',
  url: '/oss/requests',
};

export const OSS_REQUEST_ROUTE: WorkbenchRoute = {
  name: 'Open Source Request',
  key: 'oss-request',
  url: '/oss/requests/:id',
};

export const OSS_SOFTWARES_ROUTE: WorkbenchRoute = {
  name: 'Open Source Software',
  key: 'oss-softwares',
  url: '/oss/software',
};

export const OSS_SOFTWARE_ROUTE: WorkbenchRoute = {
  name: 'Open Source Software',
  key: 'oss-software',
  url: '/oss/software/:id',
};

export const OSS_PENDING_APPROVALS_ROUTE: WorkbenchRoute = {
  name: 'OSS Approval',
  key: 'oss-approval',
  url: '/oss/approvals',
};

export const SBOM_JSON_ROUTE: WorkbenchRoute = {
  name: 'SBOM JSON',
  key: 'sbom-json',
  url: '/sboms/:solutionId/:version/:file',
};


export const TOKENS_ROUTE: WorkbenchRoute = {
  name: 'API Tokens',
  key: 'tokens',
  url: '/tokens',
};

export const ROUTES = [
  ADVISORIES_ROUTE,
  ADVISORY_ROUTE,
  ADVISORY_DRAFTS_ROUTE,
  ADVISORY_DRAFT_ROUTE,
  ADVISORY_DRAFT_FORM_ROUTE,
  ADVISORY_DRAFT_EDIT_ROUTE,
  ADVISORY_QUERY_ROUTE,
  DEBUG_ROUTE,
  LOGOUT_ROUTE,
  OSS_SOFTWARES_ROUTE,
  OSS_SOFTWARE_ROUTE,
  OSS_REQUEST_FORM_ROUTE,
  OSS_REQUESTS_ROUTE,
  OSS_REQUEST_ROUTE,
  OSS_PENDING_APPROVALS_ROUTE,
  SBOM_JSON_ROUTE,
  TOKENS_ROUTE,
];
