import { useMemo } from 'react';

import { useRbac } from './useRbac';
import { useSession } from '../session';
import { ADVISORIES_ROUTE, OSS_PENDING_APPROVALS_ROUTE, OSS_SOFTWARES_ROUTE } from '../Routes';

export interface Preferences {
  homepage: string;
}

export const usePreferences = (): Preferences => {
  const { currentUser } = useSession();
  const { canApproveOssRequests, canEditOssRequests } = useRbac();
  const homepage = useMemo(() => {
    if (canApproveOssRequests) {
      return OSS_PENDING_APPROVALS_ROUTE.url;
    }
    const primaryOrg = currentUser?.primaryOrg?.toLowerCase();
    if (primaryOrg === 'tam' || primaryOrg === 'enterprise services') {
      return ADVISORIES_ROUTE.url;
    }
    if (canEditOssRequests) {
      return OSS_SOFTWARES_ROUTE.url;
    }
    return ADVISORIES_ROUTE.url;
  }, [canApproveOssRequests, canEditOssRequests, currentUser]);

  return useMemo(
    () => ({
      homepage,
    }),
    [homepage],
  );
};
