import React, { Dispatch, SetStateAction } from 'react';
import { Theme } from '@tanium/theme-data';

interface ThemeWrapperContextProps {
  setCurrentTheme: Dispatch<SetStateAction<Theme>>;
}

export const ThemeWrapperContext = React.createContext<ThemeWrapperContextProps>(
  {} as ThemeWrapperContextProps,
);
