import { useState } from 'react';
import { Config, getConfig } from '../config';

export interface ConfigResult {
  config: Config | undefined;
  error: Error | undefined;
}

export const useConfig = (): ConfigResult => {
  const [config, setConfig] = useState<Config | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  getConfig()
    .then((r) => {
      setConfig(r);
    })
    .catch((e: Error) => {
      setError(e);
    });
  return { config, error };
};
