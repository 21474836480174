import { useCallback, useMemo } from 'react';
import { useSession } from '../session';

export interface Privileges {
  canListAdvisories: boolean;
  canViewAdvisories: boolean;
  canEditAdvisories: boolean;
  canQueryAdvisories: boolean;
  canViewDrafts: boolean;
  canListDrafts: boolean;
  canEditDrafts: boolean;
  canListOssRequests: boolean;
  canViewOssRequests: boolean;
  canEditOssRequests: boolean;
  canListOssSoftware: boolean;
  canViewSBOMs: boolean;
  canCreateTokens: boolean;
  canEditTokens: boolean;
  canListTokens: boolean;
  canDeleteTokens: boolean;
  canCreateOwnTokens: boolean;
  canEditOwnTokens: boolean;
  canListOwnTokens: boolean;
  canDeleteOwnTokens: boolean;
  canApproveOssRequests: boolean;
  canApproveOssRequestsAs: (authority: string) => boolean;
}

export const useRbac = (): Privileges => {
  const { currentUser } = useSession();

  const hasPrivilege = useCallback(
    (p: string): boolean =>
      currentUser && currentUser.privileges && currentUser.privileges.indexOf(p) !== -1,
    [currentUser],
  );

  return useMemo(
    () => ({
      canListAdvisories: hasPrivilege('list-advisories'),
      canViewAdvisories: hasPrivilege('view-advisories'),
      canEditAdvisories: hasPrivilege('edit-advisories'),
      canQueryAdvisories: hasPrivilege('query-advisories'),
      canViewDrafts: hasPrivilege('view-drafts'),
      canListDrafts: hasPrivilege('list-drafts'),
      canEditDrafts: hasPrivilege('edit-drafts'),
      canListOssRequests: hasPrivilege('list-oss-requests'),
      canViewOssRequests: hasPrivilege('view-oss-requests'),
      canEditOssRequests: hasPrivilege('edit-oss-requests'),
      canListOssSoftware: hasPrivilege('list-oss-software'),
      canViewSBOMs: hasPrivilege('view-sboms'),
      canCreateTokens: hasPrivilege('create-tokens'),
      canEditTokens: hasPrivilege('edit-tokens'),
      canListTokens: hasPrivilege('view-tokens'),
      canDeleteTokens: hasPrivilege('delete-tokens'),
      canCreateOwnTokens: hasPrivilege('create-own-tokens'),
      canEditOwnTokens: hasPrivilege('edit-own-tokens'),
      canListOwnTokens: hasPrivilege('view-own-tokens'),
      canDeleteOwnTokens: hasPrivilege('delete-own-tokens'),
      canApproveOssRequests:
        hasPrivilege('approve-eng-oss-requests') ||
        hasPrivilege('approve-legal-oss-requests') ||
        hasPrivilege('approve-sec-oss-requests'),
      canApproveOssRequestsAs: (authority: string) =>
        (authority === 'engineering' && hasPrivilege('approve-eng-oss-requests')) ||
        (authority === 'legal' && hasPrivilege('approve-legal-oss-requests')) ||
        (authority === 'security' && hasPrivilege('approve-sec-oss-requests')),
    }),
    [hasPrivilege],
  );
};
