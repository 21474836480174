import React, { useCallback, useState } from 'react';
import { BRANDBLACK } from '@tanium/colors';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { Icon } from '@tanium/react-graphics';
import { Stack } from '@tanium/react-stack';
import { BackgroundLevel3 } from '@tanium/react-theme-definition';
import tanium_logo from '../../static/tanium_logo.svg';

interface LoginProps {
  onLogin: () => Promise<void>;
}

export const Login: React.VFC<LoginProps> = ({ onLogin }) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const handleOnSubmit = useCallback<React.FormEventHandler>(
    async (event) => {
      event.preventDefault();
      setLoggingIn(true);
      try {
        await onLogin();
      } catch (e) {
        // ignored
      }
      setLoggingIn(false);
    },
    [onLogin, setLoggingIn],
  );

  return (
    <Stack justifyContent="center" marginTop="150px">
      <form onSubmit={handleOnSubmit}>
        <Stack fill borderRadius={1} height="400px" width="800px" boxShadow="Overlay2">
          <Box
            borderLeftRadius={1}
            bg={BRANDBLACK}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={tanium_logo as Icon} alt="" width="200px" />
          </Box>
          <Stack
            alignItems="center"
            justifyContent="center"
            bg={BackgroundLevel3}
            borderRightRadius={1}
            direction="column"
            height="100%"
          >
            <Button disabled={loggingIn} type="submit">
              <Box width="234px">Sign In with SSO</Box>
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
