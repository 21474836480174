import { useEffect } from 'react';
import { AsyncFunction } from './useAsync';

export function useAsyncEffect<TExecFunc extends AsyncFunction>(
  execute: TExecFunc,
  deps: Parameters<TExecFunc>,
): void {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute(...deps);

    // Generally we should not disable exhaustive-deps, but here we need to merge the execute method with other
    // dependencies and we know that all dependencies are accounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps.concat(execute));
}
