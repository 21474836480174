import { matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from '../Routes';

export const useCurrentRoute = (): string | undefined => {
  const location = useLocation();
  const matches = ROUTES.filter(
    (r) => matchPath(location.pathname, { path: r.url, exact: true }) != null,
  );
  return matches[0]?.key;
};
